$brand-color: #ff006f;
$link-color: #2d699e;

@import "vendor/bootstrap/bootstrap";
@import "search";

.container {
  max-width: 970px;
}

a {
  @include transition(all 0.1s ease-in-out);
}

.branded {
  color: $brand-color !important;
}

.branded-text {
  color: $brand-color !important;
  font-family: Georgia, "Times New Roman", Serif;
}

.btn-brand {
  @include button-variant(#FFFFFF, $brand-color, darken($brand-color, 10%));
}

.navbar {
  border-bottom: 1px solid  #e7e7e7;
  margin-bottom: 1em;
}

a.navbar-brand {
  color: $brand-color !important;
  font-family: Georgia, "Times New Roman", Serif;
  font-size: 1.8em;
  font-style: italic;
  font-weight: bold;
  word-spacing: 0.1em;
  &:hover {
    color: #5e5e5e !important;
  }
}

.img-circle {
  border-radius: 50%;
}

.page-footer {
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  color: #545454;
  margin-top: 30px;
  padding: 30px 0;
  h1 {
    font-size: 18px;
  }
  a {
    font-weight: bold;
  }
}

//
// Wrapping allows a fixed footer
//

$footer-height: 120px;
$footer-padding: 20px;

html,
body {
  height: 100%;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .wrap-body {
    height: auto;
    margin-bottom: -($footer-height + $footer-padding);
    min-height: 100%;
    padding-bottom: $footer-height + $footer-padding;
  }

  .wrap-footer {
    height: $footer-height;
    width: 100%;
  }
}
